import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'
import { ImEnlarge } from "react-icons/im";
import LazyLoad from "react-lazyload"

import FullScreenImage from './FullScreenImage'
import "./Gallary.css"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button.svg"

import video1 from "../../../Assets/Works/video/knorr.webp"
import video2 from "../../../Assets/Works/video/dema.webp"
import video3 from "../../../Assets/Works/video/gitex.webp"
import video4 from "../../../Assets/Works/video/canbee.webp"
import video5 from "../../../Assets/Works/video/sedoxo.webp"
import video6 from "../../../Assets/Works/video/reyl.webp"
import video7 from "../../../Assets/Works/video/szbooks.webp"
import video8 from "../../../Assets/Works/video/phillip.webp"
import video9 from "../../../Assets/Works/video/siemens.webp"
import video10 from "../../../Assets/Works/video/arabhealth.webp"
import video11 from "../../../Assets/Works/video/hauwei.webp"
import video12 from "../../../Assets/Works/video/toshiba.webp"



const videoLinks = {
    video_1: "https://youtu.be/hydng2qSHAY",
    video_2: "https://youtu.be/tRozcrkFo78",
    video_3: "https://youtu.be/b4fqyba9NE8",
    video_4: "https://youtu.be/OUbbvRgwahI",
    video_5: "https://youtu.be/L018CSDWztg", // Sedexo
    video_6: "https://youtu.be/lKPryLvkFIU", // Reyl
    video_7: "https://youtu.be/LV1lKXw043Y", // SZ Book Awards
    video_8: "https://youtu.be/n4HnZ2NBVAo", // Phillip Morris
    video_9: "https://youtu.be/BegauEkfKKg", // Siemens
    video_10: "https://youtu.be/AZGrd4Vv6p0", // Arab Health
    video_11: "https://youtu.be/Qq0Wu19tFZI", // Huawei
    video_12: "https://youtu.be/pguITjBIvwA"  // Toshiba

    // video_9: "https://youtu.be/IzDJnXfeYuc", // Arabia
}

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Gallary() {
    const [playingVideo, setPlayingVideo] = useState(null);

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
      };

    const [playingImage, setPlayingImage] = useState(null);

    // const handleImageClick = (imageUrl) => {
    //     setPlayingImage(imageUrl);
    //   };

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading"><span>Portfolio</span></h1>

        <AnimatePresence mode="wait">
        <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
                <img src={video7} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">SHEIKH ZAYED BOOK AWARD</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
                <img src={video8} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">PHILLIP MORRIS INTERNATIONAL</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
                <img src={video9} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">SIEMENS</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_10)}>
                <img src={video10} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">ARAB HEALTH</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_11)}>
                <img src={video11} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">HUAWEI</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_12)}>
                <img src={video12} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">TOSHIBA</p>
                    </div>
                </div>
            </div>
          </motion.div>
          <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_1)}>
                <img src={video1} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">KNORR</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_2)}>
                <img src={video2} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">DERMA</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_3)}>
                <img src={video3} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">GITEX</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_4)}>
                <img src={video4} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">CANBEBE</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_5)}>
                <img src={video5} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">SODEXO</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_6)}>
                <img src={video6} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">REYL INNOVATIVE BANKING</p>
                    </div>
                </div>
            </div>
          </motion.div>
        </AnimatePresence>

{/* <       AnimatePresence mode="wait"> */}
          {/* <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants} */}
            <div
              className="button-container content-center">
            <a href="#about-contact"><button className="btn button-touch2"> Explore More
                    <span className="line">
                        <span className="circle"></span>
                    </span>
            </button></a>
            </div>
          {/* </motion.div> */}
          {/* </AnimatePresence> */}
    
    {playingVideo && (
      <div className="video-overlay">
        <LazyLoad offset={100}>
        <div className="video-player">
          <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
          </div>
          <img className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
        {/* <img className="close-button1" src={closeButton} alt=""/> */}
        </LazyLoad>
      </div>
      )}
    {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <FullScreenImage src={playingImage} />
      </div>
        <img className="close-button1" onClick={() => setPlayingImage(null)} src={closeButton}/>
      </div>
    )}
  </section>
  )
}