import React, { useEffect, useState } from 'react'
import "./Navbar.css"

import logo_white from "../../../Assets/Logo/logo3.webp"
import logo_red from "../../../Assets/Logo/logo2.webp"
import logo_name from "../../../Assets/Logo/RNC-Logo-black.webp"

import menuIcon from "../../../Assets/Common/menu-icon.svg"
import menuIconRed from "../../../Assets/Common/menu-icon-red.svg"
import close from "../../../Assets/Common/close-red.svg"
import flag from "../../../Assets/Common/UAEflag.webp"
import mail_icon from "../../../Assets/Common/mail.svg"
import phone_icon from "../../../Assets/Common/phone.svg"
import { IoMdArrowDropdown } from "react-icons/io";

import { useLocation } from 'react-router-dom'

export default function Navbar(){
  const location = useLocation()
  const [mobileMenu, setMobileMenu] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [isHover, setIsHover] = useState(false)
  // const [activeSection, setActiveSection] = useState('2d&3d-animation');

  const toggle = () => {
    setMobileMenu(!mobileMenu)
  }

  const handleHoverIn = () => {
      setIsHover(true)
  }

  const handleHoverOut = () => {
      setIsHover(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setSticky(true) : setSticky(false)
    })
  }, [])
  return (
    <div className="navbar">
      <nav className={`container ${sticky? "dark-nav":""}`}>
      {/* <div className="left"> */}
      <a href="/"><img onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} src={!sticky ? (isHover ? logo_red : logo_white) : logo_red} alt="" className={sticky ? "black-logo" : "logo"}></img></a>
      {/* </div> */}
      {/* <div className="right"> */}
      <ul className={mobileMenu ? "navbar-ul" : "hide-mobile-menu"}>
        <li><a href="#about-contact" className={`${location.hash === "#about-contact" ? "active" : ""}`}>ABOUT</a></li>
        <li><a href="#services" className={`${location.hash === "#services#" ? "active" : ""}`}>SERVICES</a></li>
        <li><a href="#portfolio" className={`${location.hash === "#portfolio" ? "active" : ""}`}>PORTFOLIO</a></li>
        <li><a href="#caseStudy" className={`${location.hash === "#caseStudy" ? "active" : ""}`}>CASE STUDIES</a></li>
        <li><a href="#testimonials" className={`${location.hash === "#testimonials" ? "active" : ""}`}>TESTIMONIALS</a></li>
        {/* Add flag, phone number, and button for mobile view */}
        <a href="#about-contact" className="btn-mobile">GET IN TOUCH</a>
        <div className="mail-phone-mobile">
          <img src={logo_name} alt="" className="side-logo"></img>
          <p>
            We’re all about creating communications that sparkle and shine for the brands we love. 
            But there’s more to us than just creating pretty pictures.
          </p>
          <a href="mailto:info@rabbitandcarrot.com" className="mail-mobile"><li><img className="mail-icon" src={mail_icon} alt=""/>info@rabbitandcarrot.com<br/></li></a>
          <a href="tel:+971542226464" className="phone-mobile"><li><img className="phone-icon" src={phone_icon} alt=""/>+971 54 222 6464</li></a>
        </div>
      </ul>
      <div className='right'>
      <div className="flag-number">
        <img src={flag} alt="" className="flag"/><a href="tel:+971542226464" className="phone-number">+971 54 222 6464</a>
      </div>
      <button className={`btn1 ${sticky? "btn2" :""}`}><a href="#about-contact">GET IN TOUCH</a></button>
      <img src={ mobileMenu ? close : sticky ? menuIconRed : menuIcon} alt="" className="menuIcon" onClick={() => toggle()}/>
      </div>
      {/* </div> */}
    </nav>
    </div>
  )
}