import { useEffect } from "react";

import AboutUs from "../Components/Common/AboutUs/AboutUs"; 
import Awards from "../Components/Common/Awards/Awards";
import Hero from "../Components/Common/Hero/Hero";
import Testimonials from "../Components/Common/Testimonials/Testimonials";
import Gallary from "../Components/Common/Gallary/Gallary";
// import GetInTouch from "../Components/Common/GetInTouch/GetInTouch";
import CaseStudy from "../Components/Common/CaseStudy/CaseStudy";
import QuickContact from "../Components/Common/QuickContact/QuickContact"
import Helmet from "../Components/Common/Helmet/Helmet";
import Services from "../Components/Common/Services/Services";
import Recognitions from "../Components/Common/Recognitions/Recognitions";
import UseCases from "../Components/Common/UseCases/UseCases";
import Process from "../Components/Common/Process/Process";

export default function Home({ activeSection, handleSectionChange, size, duration }) {
    return (
        <section>
            <Helmet title="Video Production">
                <Hero size={size}/>
                <AboutUs size={size}/>
                <Services handleSectionChange={handleSectionChange} activeSection={activeSection} size={size}/>
                <Process/>
                {/* <GetInTouch handleSectionChange={handleSectionChange} activeSection={activeSection} size={size}/> */}
                <Gallary handleSectionChange={handleSectionChange} activeSection={activeSection} size={size}/>
                <UseCases/>
                <Recognitions/>
                <CaseStudy/>
                <QuickContact/>
                <Testimonials/>
                <Awards/>
            </Helmet>
        </section>
    );
}