import React from 'react'
import phoneIcon from "../../../Assets/Common/phone-white.svg"
import chatIcon from "../../../Assets/Common/chat-white.svg"

import "./QuickContact.css"

function QuickContact() {
  return (
    <div className="quick-contact">
        <div className="overlay"></div>
        <ul>
            <img src={phoneIcon} alt="" className="phoneIcon1"/>   
            <li className="contact1">
                <a href="tel:+971542226464">
                    <span className="span-1">CALL US AT</span><br/>
                    +971 54 222 6464
                </a>
            </li>
            <li>
                <button className="btn button-touch1">
                    <a href="#about-contact">FREE CONSULTATION</a>
                    <span className="line">
                        <span className="circle"></span>
                    </span>
                </button>

            </li>
            <img src={chatIcon} alt="" className="chatIcon1"/>
            <li className="contact1">
                <a href="#about-contact">
                    <span>24/7 SUPPORT</span><br/>
                    LIVE CHAT
                </a>
            </li>
        </ul>
    </div>
  )
}

export default QuickContact